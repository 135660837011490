* {
    box-sizing:border-box;
}

html,
body {
    padding:0;
    margin:0;
    background-color: #050505;
    color: #fff;
}

html {
    font-size: calc(var(--vh) * 3);
}

#root {
    width:100%;
    text-align:center;
}

.centerBoth {
    display:flex;
    align-items: center;
    justify-items: center;
    min-height:calc(var(--vh) * 100);
}

.mediaButton {
    width: calc(var(--vw) * 20);
    height:calc(var(--vw) * 20);
    transition: filter 0.1s ease-in-out, transform 0.1s ease-in-out;
    transform: translateY(0);
    &:hover {
        filter: brightness(1.2);
    }
    &:active {
        filter: brightness(0.9);
        transform: translateY(1px);
    }
    svg {
        width: 100%;
        height: 100%;
    }
}

.customButton {
    width: calc(var(--vw) * 20);
    height:calc(var(--vw) * 20);
    background-color: rgba(0,0,0,0);
    border: 0;
    padding:0;
    margin:0;
    line-height:0;
    transition: filter 0.1s ease-in-out, transform 0.1s ease-in-out;
    transform: translateY(0);
    outline:none;
    &:hover {
        filter: brightness(1.2);
    }
    &:active {
        filter: brightness(0.9);
        transform: translateY(1px);
    }
    max-width:150px;
    img {
        width: 100%;
        height: auto;
    }
}

.watchBox{
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    .watchInfo{
        text-align:left;
        width:80%;
        padding: 3rem 0;
    }
}

.squiggle {
    width: 500px;
    max-width: calc(var(--vw) * 30);
    margin-top: calc(var(--vw) * 5);
}

.squiggleBox {
    text-align:center;
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
}

.liveNotice{
    position: fixed;
    left:0;
    right:0;
    top:0;
    text-align:center;
}

.stream,
.login{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    max-width: calc(var(--vw) * 100);
    img {
        width:100%;
        height:auto;
    }
}

.smallLogo {
    display:flex;
    flex-direction: column;
    align-items: center;
    .logo {
        max-width: 20rem;
        img {
            max-width: 100%;
            width: auto;
        }
    }
}

.liveNotice {
    font-size: 2rem;
    color: red;
}

.help {
    padding: calc(var(--vw) * 5);
    a {
        color: pink;
    }
}